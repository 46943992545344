window.$ = window.jQuery = require('jquery')

require('magnific-popup')
require('./handlers/spriteSvg')
require('./vendors/datePicker/air-datepicker')
require('./vendors/tooltipster')
require('./vendors/jquery.sumoselect')
require('./vendors/tree')
// require('./vendors/jquery.validate.min')

import SimpleBar from 'simplebar'
import IMask from 'imask'
import autosize from 'autosize'

import dev from './dev'

import CustomSelect from './handlers/custom-select'
import inputOkved from './handlers/form/okved'
import initDropzone from './handlers/form/dropzone'
import clearableSearch from './handlers/form/clerable-search'

import { modalHandler, modalGallery } from './handlers/modals'
import Accordion from './handlers/accordion'
import TabsContent from './handlers/tabs-content'
import ScrollElement from './handlers/scroll'
import common from './handlers/common'
import sliders from './handlers/sliders'
import ToggleTariffs from './handlers/toggleTariffs'
import ListTabs from './handlers/test'
import footer from './handlers/footer/footer'
import Table from './handlers/Table'
// import initValidate from './handlers/validate'
import sidebarNav from './handlers/mobile/sidebar-nav'
import indexNews from "./handlers/news/index";


function init() {

  indexNews.init()

  /**
   * Разные события
   */
  common.init()

  /**
   * Инит оквед
   */
  inputOkved.init()

  /**
   * Инит фикс шапки
   */
  footer.init('.js-footer')

  initDropzone()

  /**
   * Разные события
   */
  dev.init()

  /**
   * Инит очистки поисковой строки
  */
  clearableSearch.init()

  /**
   * Инит событий мобильного меню
   */
  sidebarNav.init()

  /**
   * Инициализация календаря
  */
  if ($(document).find('.js-radiobutton-calendar').length) {
    $('.js-radiobutton-calendar').datepicker({
      range: true,
      maxDate: new Date(),
      multipleDatesSeparator: '-',
      autoClose: true,
      onSelect: function (fd) {
        let element = {}
        element.XML_ID = fd

        $('.js-radiobutton-calendar-value').prop('checked', true)
        $('.js-radiobutton-calendar-label').html(fd ? fd : 'Выбрать дату')
        BX.MSP.NewsFilterLogic.filterChange('Date', element)
      },
      onShow: function () {
        $('.js-radiobutton-calendar-btn').addClass('btn-action_active')
      },
      onHide: function () {
        $('.js-radiobutton-calendar-btn').removeClass('btn-action_active')
      }
    })
  }

  $('.js-input-calendar').each(function () {
    $(this).datepicker()
  })

  /**
   * Инициализация слайдеров
   */
  sliders.init()
  const instanceSliders = sliders.getSliders();

  if($("button").hasClass("js-remove-slide")) {
    $(document).on("click", ".js-remove-slide", function(e){
      e.preventDefault()
      const key = $(this).parents(".js-slider").data("slider-id");
      instanceSliders.forEach(slider => {
        const sliderId = $(slider.$el).attr("data-slider-id");
        if(sliderId === key) {
          const swiperSlidesLength = ($("[data-slider-id=" + sliderId + "] .swiper-slide").length - 1);
          slider.removeSlide(slider.activeIndex);

          const bannerSectionSupport = $(".dashboard-section_support.is-hide");
          if(swiperSlidesLength === 0 ){
            slider.destroy()
            $("[data-slider-id=" + sliderId + "]").parent().slideUp(300);
            if(sliderId === "lk_notification"){
              bannerSectionSupport.removeClass("is-hide")
            }
          }else{
            bannerSectionSupport.addClass("is-hide")
          }
        }
      })
    })
  }


  // предзаполненные поля в форме - происходит за счет постоянно видимой маски, куда через data-before опрокидывается нужный текст
  if ($('.js-label-before').length > 0) {
    $('.js-label-before').each(function (i, item) {
      const label = $(item).data('label')

      const maskOptions = {
        mask: `${label} «value»`,
        blocks: {
          value: {
            mask: /^[а-яА-Я0-9M{0,3}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3}) "]+$/,
          }
        },
        lazy: false,
      }

      const mask = IMask(item, maskOptions)
    })
  }

  // увеличение высоты textarea в зависимости от наполнения контентом
  if ($('.js-textarea').length > 0) {
    $(window).on('load', () => {
      autosize($('.js-textarea'))
    })
  }

  /**
  * Инициализация шагов
  */
  $('.js-table-memo').each(function () {
    new Table($(this))
  })

  /**
  * Инициализация шагов
  */
  $('.js-list-step').each(function () {
    new ListTabs($(this))
  })

  /**
  * Подтверждение пароля, перескакивание к следующему
  */
  $('.js-confirm-codes').each(function () {
    new ConfirmCode($(this))
  })

  /**
  * Инициализация для показать/скрыть тарифы
  */
  $('.js-detail-tariffs').each(function () {
    new ToggleTariffs($(this))
  })

  /**
   * Инициализация скролбара - только при ширине экрана больше 1024
  */
  $('.js-scrollbar').each(function () {
    if ($(window).width() > 1024) {
      new SimpleBar($(this)[0])
    }
  })

  /**
   * Инициализация аккордиона
  */
  $('.js-accordion-item').each(function () {
    new Accordion($(this))
  })

  /**
    * Инициализация горизонтальных табов
  */
  $('.js-tabs').each(function () {
    new TabsContent($(this))
  })

  /**
   * Инициализация скролл элементов
  */
  $('.js-scroll-wrapper').each(function () {
    new ScrollElement($(this))
  })

  /**
   * Инициализация модальных окон
   */
  modalHandler.init()
  modalGallery.event_handler()

  /**
   * Обработчик поведение списков выбора
   */
  $('.js-custom-select').each(function () {
    new CustomSelect($(this))
  })

  $('.js-custom-select-radio').each(function () {
    new CustomSelect($(this), 'radio')
  })

  // Инициализация селекта
  $('.js-select').SumoSelect({
    search: true,
    forceCustomRendering: true,
    searchFn: function (haystack, needle, el) {
      if (needle.match(/[!@#$%^&*№;%:?*(_+<>:")"{}]/g)) {
        $(el[0])
          .parents(".SumoSelect")
          .find(".search-txt")
          .val(needle.replace(/[!@#$%^&*№;%:?*(_+<>:")"{}]/g, ""));
      }
    }
  })


  $('.js-competence').on('click', function () {
    $(this).addClass('is_active').siblings().removeClass('is_active')
  })

  $('.js-show-labels').on('click', (e) => {
    const $target = $(e.currentTarget)
    const $cardLabels = $target.parents('.js-card-labels')
    const $hiddenLabels = $cardLabels.find('.js-hidden-labels')

    $target.toggleClass('is-show')
    $hiddenLabels.toggle()
  })

  $('.js-open-menu').on('click', () => {
    $('.js-modal-menu').fadeIn()
  })

  $('.js-close-menu').on('click', (e) => {
    $(e.currentTarget).parents('.js-modal-menu').fadeOut()
  })

  // инициализация валидатора - юзать только для теста локально, сам валидатор на бэке
  // initValidate()

  // тултип для полного названия бизнеса появляется если он не помещается в блок

  const $businessName = $('.js-business-name')

  function isEllipsisActive(e) {
    return (e.offsetWidth < e.scrollWidth);
  }

  if ($businessName.length && isEllipsisActive($businessName[0])) {
    $('.js-name-tooltip').show()
  }

  $('.js-name-tooltip').on('click', (e) => {
    $(e.currentTarget).toggleClass('is-open')
  })
}

export default init