const inputOkved = {

  init() {
    this.events()
  },

  events() {

    function isChecked($el) {
      let $li = $($el).closest('li')

      if ($li.find('.js-li-parent').length) {
        if (!$li.parent().hasClass('hummingbird-base')) isChecked($li.parent())

        let $parent = $li.find('.js-li-parent').first(),
          check = $li.find('input').filter((i,e) => $(e).prop('checked'))

        if (check.length) {
          $parent.addClass('hummingbird-base__checkbox_parent_checked')
        }
        else {
          $parent.removeClass('hummingbird-base__checkbox_parent_checked')
        }
      }
      else {
        isChecked($li.parent())
      }
    }

    setTimeout(() => {
      $(document).on('change', '#treeview [type="checkbox"]', (e) => {
        const treeview2 = $('#treeview2');
        const id = $(e.currentTarget).data('id');
        const isChecked = $(e.currentTarget).is(':checked');

        treeview2.find(`input[data-id="${id}"]`).prop('disabled', isChecked);
      });

      $(document).on('change', '#treeview2 [type="checkbox"]', (e) => {
        const treeview = $('#treeview');
        const id = $(e.currentTarget).data('id');
        const isChecked = $(e.currentTarget).is(':checked');

        treeview.find(`input[data-id="${id}"]`).prop('disabled', isChecked);
      });

      // Убрать инпуты с id меньше 5 символов
      let $inputs = $('.hummingbird-base').find('input')
      if ($inputs.length > 0) {
        $inputs.each(function () {
          if ($(this).attr('data-id').length < 5) {
            let parent = $(this).parent()
            $(parent).addClass('js-li-parent hummingbird-base__checkbox_parent')
            $(parent).find('.checkbox__view').remove()
            $(this).remove()
          }
        })
      }

      if (document.getElementById('treeview')) {
        $.fn.hummingbird.defaults.collapsedSymbol = "fa-angle-down"
        $.fn.hummingbird.defaults.expandedSymbol = "fa-angle-up"

        $.fn.hummingbird.defaults.checkboxesGroups = "enabled"
        $("#treeview").hummingbird()
        let $inputsTreeview = $("#treeview").find('input')
        $inputsTreeview.addClass('hummingbird-end-node')

        if ($('#treeview-checked-id').length) {
          let treeviewCheckedNodes = $('#treeview-checked-id').val().split(',')[0]
          let ListTreeview = { "id": [], "dataid": [], "text": [] }

          if (treeviewCheckedNodes.length > 4) {
            $("#treeview").find(`[data-id="${treeviewCheckedNodes}"]`).trigger('click')
            $("#treeview").hummingbird("getChecked", { list: ListTreeview, onlyEndNodes: true, onlyParents: false, fromThis: false })
            $('#treeview-select-label').html(ListTreeview.text[ListTreeview.text.length - 1])
          }
        }

        $inputsTreeview.each(function () {

          $(this).on('change', function () {
            let ListTreeview = { "id": [], "dataid": [], "text": [] }
            isChecked($(this))

            $("#treeview").hummingbird("getChecked", { list: ListTreeview, onlyEndNodes: true, onlyParents: false, fromThis: false })

            if (ListTreeview.id.length > 1) {
              let id = 0
              $inputsTreeview.each(function () {
                $(this).prop('checked', false)
              })

              if (ListTreeview.dataid.indexOf($(this).attr('data-id'))) {
                id = ListTreeview.dataid[0]
              }
              else {
                id = ListTreeview.dataid[ListTreeview.dataid.length - 1]
              }

              $("#treeview2")
                .find(`input[data-id="${id}"]`)
                .prop('disabled', false)

              setTimeout(() => {
                $(this).trigger('click')
                isChecked($(this))
                $("#treeview").hummingbird("getChecked", { list: ListTreeview, onlyEndNodes: true, onlyParents: false, fromThis: false })
              }, 200);
            }

            $('#treeview-select-label').html(ListTreeview.text.length ? ListTreeview.text[ListTreeview.text.length - 1] : 'Выберите основной вид деятельности')
            $('#treeview-selected-id').val(ListTreeview.dataid[ListTreeview.dataid.length - 1]).trigger('change')
          })
        })

        if ($('#treeview-checked-id') && $('#treeview-checked-id').val().length) {
          if ($('#treeview-checked-id').val().split(',')[0].length > 4) {
            let $input = $("#treeview").find(`[data-id="${$('#treeview-checked-id').val().split(',')[0]}"]`)
            isChecked($input)
          }
        }
      }

      if (document.getElementById('treeview2')) {
        $.fn.hummingbird.defaults.collapsedSymbol = "fa-angle-down"
        $.fn.hummingbird.defaults.expandedSymbol = "fa-angle-up"
        $.fn.hummingbird.defaults.checkboxesGroups = "enabled"
        $("#treeview2").hummingbird()
        let $inputsTreeview2 = $("#treeview2").find('input')
        let checkedInputs = 0;
        $inputsTreeview2.addClass('hummingbird-end-node')

        // Прочеканные заранее
        if ($('#treeview2-checked-id').length) {
          let treeview2checkedNodes = $('#treeview2-checked-id').val().split(',')
          let Listtreeview2 = { "id": [], "dataid": [], "text": [] }
          let treeview2checkedNodesFiltered = treeview2checkedNodes.filter(e => e.length > 4)

          if (treeview2checkedNodesFiltered.length) {
            treeview2checkedNodesFiltered.forEach( e => {
              let $input = $("#treeview2").find(`[data-id="${e}"]`)
              $input.trigger('click')
            })

            $("#treeview2").hummingbird("getChecked", { list: Listtreeview2, onlyEndNodes: true, onlyParents: false, fromThis: false })
            $('#treeview2-select-label').html(treeview2checkedNodesFiltered.length)
            checkedInputs = treeview2checkedNodesFiltered.length
          }
        }

        $inputsTreeview2.each(function () {
          $(this).on('change', function (e) {
            if (checkedInputs >= 68) {
              $(this).prop('checked', false)
            }
            isChecked($(this))
            let Listtreeview2 = { "id": [], "dataid": [], "text": [] }
            $("#treeview2").hummingbird("getChecked", { list: Listtreeview2, onlyEndNodes: true, onlyParents: false, fromThis: false })

            checkedInputs = Listtreeview2.dataid.length
            $('#treeview2-select-label').html(Listtreeview2.dataid.length)
            $('#treeview2-selected-id').val(Listtreeview2.dataid.join(", ")).trigger('change')
          })
        })

        if ($('#treeview2-checked-id').length > 0 && $('#treeview2-checked-id').val().length) {
          let treeview2checkedNodesFiltered = $('#treeview2-checked-id').val().split(',').filter(e => e.length > 4)
          if (treeview2checkedNodesFiltered.length > 0) {
            treeview2checkedNodesFiltered.forEach( e => {
              let $input = $("#treeview2").find(`[data-id="${e}"]`)
              isChecked($input)
            })
          }
        }
      }

      // Сбросить чеки
      $('#treeview-reset').on('click', function () {
        $("#treeview").hummingbird('uncheckAll')
        $('#treeview').find('.js-li-parent').removeClass('hummingbird-base__checkbox_parent_checked')
        $('#treeview-select-label').html('Выберите основной вид деятельности')
        $('#treeview2 input[type="checkbox"]').prop('disabled', false)
      })
      // Сбросить чеки
      $('#treeview2-reset').on('click', function () {
        $("#treeview2").hummingbird('uncheckAll')
        $('#treeview2').find('.js-li-parent').removeClass('hummingbird-base__checkbox_parent_checked')
        $('#treeview2-select-label').html('0')
        $('#treeview input[type="checkbox"]').prop('disabled', false)
        treeviewDisabledCheckbox()
      })

      // Применить
      $('#treeview-apply').on('click', function () {
        let List = { "id": [], "dataid": [], "text": [] }
        $("#treeview").hummingbird("getChecked", { list: List, onlyEndNodes: true, onlyParents: false, fromThis: false })
        $('#treeview-selected-id').val(List.dataid.join(", "))
      })

      $('#treeview2-apply').on('click', function () {
        let List = { "id": [], "dataid": [], "text": [] }
        $("#treeview2").hummingbird("getChecked", { list: List, onlyEndNodes: true, onlyParents: false, fromThis: false })
        $('#treeview2-selected-id').val(List.dataid.join(", "))
      })
    }, 0);

    function treeviewDisabledCheckbox() {
      const treeview2 = $('#treeview2');
      const checkboxs = $('#treeview input[type="checkbox"]');

      checkboxs.each((index, el) => {
        const id = $(el).attr('data-id');
        const isChecked = $(el).is(':checked');

        treeview2.find(`input[data-id="${id}"]`).prop('disabled', isChecked);
      });
    }
  }
}

export default inputOkved
