import Swiper from 'swiper'

const sliders = {
  commonConf: {
    observer: true,
    observeParents: true,
    pagination: {
      el: '.slider-pagination',
      type: 'bullets',
      clickable: true,
    }
  },
  config: {
    lk_notification: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    lk_notification_horizontal: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    lk_support: {
      slidesPerView: "auto",
      freeMode: true,
      spaceBetween: 24,
    },
    three: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    two: {
      slidesPerView: 1,
      spaceBetween: 20,
      autoHeight: true,

      breakpoints: {
        1025: {
          slidesPerView: 2,
          spaceBetween: 20,
        }
      }
    },
    anti_crisis: {
      slidesPerView: 1,
      spaceBetween: 20,

      breakpoints: {
        1025: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        }
      }
    },
    auto: {
      slidesPerView: 'auto',
      freeMode: true,
      spaceBetween: 12,

      breakpoints: {
        1025: {
          spaceBetween: 20
        }
      }
    },
    home_news: {
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 500,
      autoHeight: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      }
    },
    default: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    gallery: {
      slidesPerView: 1,
      spaceBetween: 0,
      autoHeight: true,
    },
    events: {
      slidesPerView: 'auto',
      spaceBetween: 12,
    },
    eventsPromo: {
      slidesPerView: '2',
      spaceBetween: 12,
      breakpoints: {
        1025: {
          slidesPerView: 2,
          autoHeight: false
        },
        320: {
          slidesPerView: 1,
          autoHeight: false
        }
      }
    },
    accelerator: {
      slidesPerView: 'auto',
      spaceBetween: 12,
    },
    news: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 12,
      autoHeight: true,

      breakpoints: {
        1025: {
          slidesPerView: 'auto',
          slidesPerGroup: 4,
          autoHeight: false,
        },
        767: {
          slidesPerView: 2,
          autoHeight: false,
        }
      }
    },
    software_detail: {
      slidesPerView: 3,
      spaceBetween: 28,
      autoHeight: false,
      breakpoints: {
        1100: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 1,
        }
      }
    },
    software_promo: {
      slidesPerView: 6,
      spaceBetween: 12,
      autoHeight: false,
      breakpoints: {
        1100: {
          slidesPerView: 6,
        },
        768: {
          slidesPerView: 4,
        },
        320: {
          slidesPerView: 2,
        }
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      }
    },
    serv_dev: {
      slidesPerView: 6,
      slidesPerGroup: 6,
      spaceBetween: 12,
      autoHeight: false,
      breakpoints: {
        1150: {
          slidesPerView: 6,
          slidesPerGroup: 6
        },
        750: {
          slidesPerView: 4,
          slidesPerGroup: 4
        },
        550: {
          slidesPerView: 3,
          slidesPerGroup: 3
        },
        300: {
          slidesPerView: 2,
          slidesPerGroup: 2
        },
        150: {
          slidesPerView: 1,
          slidesPerGroup: 1
        },
        50: {
          slidesPerView: 1,
          slidesPerGroup: 1
        }
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      }
    },
    serv_dev_olympiad: {
      slidesPerView: 6,
      slidesPerGroup: 6,
      spaceBetween: 12,
      autoHeight: false,
      breakpoints: {
        1150: {
          slidesPerView: 6,
          slidesPerGroup: 6
        },
        750: {
          slidesPerView: 3,
          slidesPerGroup: 3
        },
        550: {
          slidesPerView: 3,
          slidesPerGroup: 3
        },
        300: {
          slidesPerView: 2,
          slidesPerGroup: 2
        },
        150: {
          slidesPerView: 1,
          slidesPerGroup: 1
        },
        50: {
          slidesPerView: 1,
          slidesPerGroup: 1
        }
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      }
    },
    collaboration_olympiad: {
      slidesPerView: 5,
      slidesPerGroup: 5,
      spaceBetween: 36,
      autoHeight: false,
      breakpoints: {
        1150: {
          slidesPerView: 5,
          slidesPerGroup: 5
        },
        750: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 32
        },
        550: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 32
        },
        300: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 10
        },
        150: {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 10
        },
        50: {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 10
        }
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      }
    },
    serv_dev_four: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 12,
      autoHeight: false,
      breakpoints: {
        750: {
          slidesPerView: 4,
          slidesPerGroup: 4
        },
        550: {
          slidesPerView: 3,
          slidesPerGroup: 3
        },
        300: {
          slidesPerView: 2,
          slidesPerGroup: 2
        },
        150: {
          slidesPerView: 1,
          slidesPerGroup: 1
        },
        50: {
          slidesPerView: 1,
          slidesPerGroup: 1
        }
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      }
    },
  },

  init() {
    this.eventHandlers()
  },

  eventHandlers() {
    const self = this
    const mapSliders = new Map()

    $('.js-slider').each(function (i, e) {
      const sliderId = $(e).attr('data-slider-id')
      mapSliders.set(sliderId)
    })

    if (mapSliders.size){

      for (const key of mapSliders.keys()) {
        const slider = new Swiper(`[data-slider-id=${key}`, Object.assign(self.config[key], self.commonConf))

        if (Object.keys(self.config[key]).includes('autoplay')) {
          let autoplayDelay = Object.entries(self.config[key]).filter(e => e[0] === 'autoplay')[0][1].delay / 1000;
          if(slider.$el) {
            let $paginationBullets = $(slider.$el).find('.swiper-pagination-bullet'),
              $slides = slider.slides,
              arSlides = Array.from($slides)

            const filterSlidesDelay = arSlides.filter(slide => {
              return slide.hasAttribute('data-swiper-autoplay')
            })

            if (filterSlidesDelay.length > 0) {
              arSlides.forEach((slide, key) => {
                if (slide.dataset.swiperAutoplay) {
                  const autoplayDelay = parseInt(slide.dataset.swiperAutoplay) / 1000
                  $($paginationBullets[key]).addClass('swiper-pagination-bullet_timer').html(`<span class="swiper-pagination-bullet__timer-bckg" style="--timer-num: ${autoplayDelay + 's'};"></span>`)
                } else {
                  $paginationBullets.addClass('swiper-pagination-bullet_timer').html(`<span class="swiper-pagination-bullet__timer-bckg" style="--timer-num: ${autoplayDelay + 's'};"></span>`);
                }
              })
            } else {
              $paginationBullets.addClass('swiper-pagination-bullet_timer').html(`<span class="swiper-pagination-bullet__timer-bckg" style="--timer-num: ${autoplayDelay + 's'};"></span>`);
            }
          } else if(slider.forEach){
            slider.forEach(sl => {
              let $paginationBullets = $(sl.$el).find('.swiper-pagination-bullet');
              $paginationBullets.addClass('swiper-pagination-bullet_timer').html(`<span class="swiper-pagination-bullet__timer-bckg" style="--timer-num: ${autoplayDelay + 's'};"></span>`);
            });
          }
        }

        if (slider instanceof Array) {
          // Обработка слайдеров, которые имеют одинаковый sliderId и добавляются в массив
          slider.forEach(e => {
            self.checkCountSlide(e);
            self.setEvents(e);
          })
        }
        else {
          self.checkCountSlide(slider);
          self.setEvents(slider);
        }

        self.saveSliders(slider)
      }
    }
  },

  arraySliders: [],
  saveSliders(slide){
    this.arraySliders.push(slide)
  },

  getSliders() {
    return this.arraySliders;
  },

  setControls(e) {
    let $btnPrev = $(e.$el).find('.js-slider-prev')
    let $btnNext = $(e.$el).find('.js-slider-next')

    $($btnPrev).on('click', () => e.slidePrev())
    $($btnNext).on('click', () => e.slideNext())
  },

  addActiveSlider(slider) {
    // При классе active появляется возможность добавлять классы для слайдера с количеством слайдов > 1
    // Slider-controls скрываем в стилях по дефолту, чтобы избежать дерганья при прогрузке скриптов
    // Если слайдов больше > 1, то добавляем видимость через класс active
    $(slider.$el).find('.swiper-wrapper').addClass( "active" );
    $(slider.$el).find('.slider-controls').addClass( "active" );
  },

  addDisabledSlider(slider) {
    // Если слайдов только 1, то добавляет disabled.
    // Можно прописать дефолтные стили для предотвращения дергания блоков при прогрузке скриптов слайда
    $(slider.$el).find('.swiper-wrapper').addClass( "disabled" );
  },

  setEvents(slider) {
    slider.on('slideChangeTransitionStart', function () {
      $(slider.$el).find('.js-tip-fh,.js-tip-fc').each((idx, el) => {
        $(el).tooltipster('hide');
        $(el).tooltipster('disable');
      });
    });
    slider.on('slideChangeTransitionEnd', function () {
      $(slider.$el).find('.js-tip-fh,.js-tip-fc').each((idx, el) => {
        $(el).tooltipster('enable');
      });
    });
  },

  checkCountSlide(slider) {
    // Формируем slidesPerView в зависимости от значения "auto"
    const slidesPerView = slider.params.slidesPerView === 'auto' ? slider.slides.length : slider.params.slidesPerView;
    // Если количество слайдов 'auto', считаем не количество слайдов, а их ширину и сравниваем с шириной общего контейнера
    const isSlidesFullWidth = slider.virtualSize > slider.width;
    if (
      (slider.params.slidesPerView === 'auto' && isSlidesFullWidth) ||
      slider.slides.length > slidesPerView ||
      slider.$el[0].dataset.sliderId === "gallery"
      ) {
      this.setControls(slider)
      this.addActiveSlider(slider)
    }
    else {
      this.addDisabledSlider(slider)
    }
  }
}

export default sliders
