const footer = {
  init(footerClass) {
    this.$footer = document.querySelector(footerClass)
    this.events()
  },

  getRoot() {
    return document.querySelector(':root')
  },

  events() {
    const $rootHtml = this.getRoot()

    $rootHtml.style.setProperty('--footer-height', this.$footer.offsetHeight + 'px')
  },
}

export default footer